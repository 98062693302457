import axios from "axios"

let cachedConfig: Record<string, any> | null = null;

export async function getConfig() {
    if (!cachedConfig) {
        console.log("Fetching config from DB...");
        const API_HOST = process.env.NEXT_PUBLIC_API_ENDPOINT || 'http://localhost';
        const authenticated = false
        const configEndpoint = `${API_HOST}/${
            authenticated ? "api" : "public"
        }/remote/config`
        const configHeaders = {}
        const response = await axios.get(configEndpoint, {
            headers: configHeaders,
        })
        if (response?.status === 200 || response?.data?.status === "success") { 
            const { data } = response 
            cachedConfig = data?.message
        } else {
            console.error("Error fetching config from DB...");
            cachedConfig = {}
        }
    }
    return cachedConfig;
}
