const MetaTags = () => {
  return <>
      {/* Primary Meta Tags */}
      <meta charSet="UTF-8" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta name="viewport" content="width=device-width, initial-scale=0.67" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <title>uGeo - Personalized Local Search</title>
      <meta name="description" content="Explore your neighborhood with personalized search! Find local spots and events tailored to your interests for a unique, local experience." data-sentry-element="meta" data-sentry-source-file="index.tsx" />

      {/* Canonical URL */}
      <link rel="canonical" href="https://ugeo.app" />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content="uGeo - Personalized Local Search" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta property="og:description" content="Explore your neighborhood with personalized search! Find local spots and events tailored to your interests for a unique, local experience." data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta property="og:url" content="https://ugeo.app" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta name="og:logo" content="https://ugeo.app/images/ugeologo.png" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta property="og:image" content="https://ugeo.app/images/ugeologo.png" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta property="og:site_name" content="uGeo" data-sentry-element="meta" data-sentry-source-file="index.tsx" />

      {/* Twitter Card / Twitter */}
      <meta name="twitter:card" content="summary_large_image" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta name="twitter:title" content="uGeo - Personalized Local Search" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta name="twitter:description" content="Explore your neighborhood with personalized search! Find local spots and events tailored to your interests for a unique, local experience." data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta name="twitter:image" content="https://ugeo.app/images/ugeologo.png" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      <meta name="twitter:site" content="@uGeoApp" data-sentry-element="meta" data-sentry-source-file="index.tsx" />

      {/* Robots */}
      <meta name="robots" content="index, follow" data-sentry-element="meta" data-sentry-source-file="index.tsx" />

      {/* Author */}
      <meta name="author" content="GeoCloud Technologies, Inc." data-sentry-element="meta" data-sentry-source-file="index.tsx" />

      {/* Keywords */}
      <meta name="keywords" content="ugeo, local, events, places, interest, social, map, personalized, groups, location, tags, experience, personalized search, local search, live locally, tailored experiences, local recommendations, local events, neighborhood discovery, interest-based search, custom search results, nearby spots, explore locally, local lifestyle, find local gems, local hotspots, discover nearby places, personalized suggestions, explore your city, local activities, unique local experiences, local events near me, things to do nearby, local favorites, explore your neighborhood, custom local recommendations, interest-based recommendations, hidden gems near me, tailored local search, local businesses, places to visit nearby, personalized local guide, local attractions, live like a local, explore local culture, discover local venues, personalized local adventures, best local spots, find local treasures, interest-driven search" data-sentry-element="meta" data-sentry-source-file="index.tsx" />

      {/* Favicon */}
      <link rel="icon" href="https://ugeo.app/favicon.ico" type="image/x-icon" />

      {/* Additional Meta Tags (for better SEO) */}
      <meta property="og:locale" content="en_US" data-sentry-element="meta" data-sentry-source-file="index.tsx" />
      {/* <meta property="og:updated_time" content="2024-10-20T12:00:00+00:00" /> */}
      {/* <meta name="theme-color" content="#333333" /> */}
    </>;
};
export { MetaTags };