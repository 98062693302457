"use client";

import "jsvectormap/dist/jsvectormap.css";
import "flatpickr/dist/flatpickr.min.css";
import "@/css/satoshi.css";
import "@/css/style.css";
import React, { useEffect, useState, useRef } from "react";
import Loader from "@/components/Common/Loader";
import { LocationProvider } from "@/components/Location/Context";
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from '@/utils/apolloClient';
import { obfuscateClassNames, generateDynamicCSS } from '@/utils/classNameObfuscator';
import { Analytics } from "@vercel/analytics/react";
import { MetaTags } from "@/components/MetaTags";
import Script from 'next/script';
// import OneSignal from 'react-onesignal';
import { ConfigProvider } from "@/context/ConfigContext";
import { getConfig } from "@/remote/config";
export default function RootLayout({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const didRun = useRef(false);
  const [jQueryLoaded, setJQueryLoaded] = useState(false);
  const [config, setConfig] = useState<Record<string, any> | null>(null);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const client = createApolloClient(authenticated);
  useEffect(() => {
    (async () => {
      if (!config) {
        setConfig((await getConfig()) || {});
      }
    })();
    setTimeout(() => setLoading(false), 500);
    return () => {};
  });

  // <script>
  //   document.addEventListener('contextmenu', function(e) {
  //     e.preventDefault();
  //   });
  //   document.addEventListener('selectstart', function(e) {
  //     e.preventDefault();
  //   });
  // </script>

  // TODO: Review scalable CSS obfuscation approaches 
  const originalClassNames = "dark:bg-boxdark-2 dark:text-bodydark";
  const obfuscatedClassNames = obfuscateClassNames(originalClassNames);
  useEffect(() => {
    if (didRun.current) return;
    didRun.current = true;
    // Create a <style> tag and inject generated CSS into the document
    const style = document.createElement('style');
    style.innerHTML = generateDynamicCSS();
    document.head.appendChild(style);

    // Ensure this code runs only on the client side
    if (typeof window !== 'undefined') {
      // OneSignal.init({ // TODO: Review
      //   appId: `${process.env.NEXT_PUBLIC_ONESIGNAL_APP_ID}`,
      //   // You can add other initialization options here
      //   notifyButton: {
      //     // NOTE: "subscribe to our notifications for the latest news and updates"
      //     enable: false,
      //   },
      //   // serviceWorkerPath: "scripts/OneSignalSDKWorker.js",
      //   // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
      //   // allowLocalhostAsSecureOrigin: true
      // });
    }
    return () => {
      document.head.removeChild(style); // Clean up on unmount
    };
  }, []);
  return <html lang="en" style={{
    height: "100%"
  }} suppressHydrationWarning data-sentry-component="RootLayout" data-sentry-source-file="layout.tsx">
      <MetaTags data-sentry-element="MetaTags" data-sentry-source-file="layout.tsx" />
      <body suppressHydrationWarning={true} style={{
      height: "100%"
    }}>
        <div className={obfuscatedClassNames} style={{
        height: "100%"
      }}>
          <ConfigProvider config={config} data-sentry-element="ConfigProvider" data-sentry-source-file="layout.tsx">
            <ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-source-file="layout.tsx">
              <LocationProvider data-sentry-element="LocationProvider" data-sentry-source-file="layout.tsx">
                {loading ? <Loader /> : children}
                <Analytics data-sentry-element="Analytics" data-sentry-source-file="layout.tsx" />
              </LocationProvider> 
            </ApolloProvider>
          </ConfigProvider>
        </div>
      </body>
      <Script id="branch-api-key" strategy="afterInteractive" dangerouslySetInnerHTML={{
      __html: `window.branchApiKey = "${process.env.NEXT_PUBLIC_BRANCH_API_KEY}";`
    }} data-sentry-element="Script" data-sentry-source-file="layout.tsx" />
      <Script src="/scripts/loadBranch.js" strategy="afterInteractive" onLoad={() => setJQueryLoaded(true)} data-sentry-element="Script" data-sentry-source-file="layout.tsx" />
    </html>;
}